import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { setGuestsChecked, setBookingData } from '../ListingPageSlice';
import Button, { ButtonSizeVariant, ButtonOutlineVariant, ButtonColorVariant, } from '../../../styled-components/Button/Button';
import BookingExtra, { BookingExtraType, } from '../../../styled-components/BookingExtra/BookingExtra';
const { Money } = sdkTypes;
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
`;
const NumberInputWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;
const ButtonArea = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.showResetButton ? 'space-between' : 'flex-end'};
`;
const BookingExtrasSelection = ({ onClose, hideContinueButton, single, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { bookingData: { extras }, listing, } = useSelector((state) => state.ListingPage);
    const { powerSupply: powerSupplySelected, firewood: firewoodSelected } = extras || {};
    useEffect(() => {
        return () => {
            dispatch(setGuestsChecked(true));
        };
    }, []);
    const { publicData } = (listing === null || listing === void 0 ? void 0 : listing.attributes) || {};
    const { pricing, currency } = publicData || {};
    const { powerSupply, firewood } = pricing || {};
    const availableExtras = [];
    if (powerSupply) {
        availableExtras.push(BookingExtraType.PowerSupply);
    }
    if (firewood) {
        availableExtras.push(BookingExtraType.Firewood);
    }
    const selectedExtras = {
        [BookingExtraType.Firewood]: !!firewoodSelected,
        [BookingExtraType.PowerSupply]: !!powerSupplySelected,
    };
    const extrasPricing = {
        [BookingExtraType.Firewood]: new Money(firewood || 0, currency),
        [BookingExtraType.PowerSupply]: new Money(powerSupply || 0, currency),
    };
    const onClickSaveExtras = () => {
        if (onClose)
            onClose();
    };
    const onClickResetExtras = () => {
        dispatch(setBookingData({ extras: { powerSupply: false, firewood: false } }));
    };
    const showResetButton = !!(firewoodSelected || powerSupplySelected);
    return (_jsxs(ContentWrap, { children: [_jsx(NumberInputWrapper, { children: availableExtras
                    .filter(extra => (single ? single === extra : true))
                    .map(extra => (_jsx(BookingExtra, { type: extra, checked: selectedExtras[extra], title: intl.formatMessage({
                        id: `BookingExtrasSelection.${extra}.title`,
                    }), description: intl.formatMessage({
                        id: `BookingExtrasSelection.${extra}.description`,
                    }), price: extrasPricing[extra], onChange: (value) => dispatch(setBookingData({ extras: { [extra]: value } })) }, extra))) }), !single && (_jsxs(ButtonArea, Object.assign({ showResetButton: showResetButton }, { children: [showResetButton && (_jsx("div", { children: _jsx(Button, { onClick: onClickResetExtras, label: intl.formatMessage({ id: 'ListingPage.reset' }), sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined }) })), !hideContinueButton && (_jsx("div", { children: _jsx(Button, { dataTestId: "BookingExtrasSelection_SaveButton", onClick: onClickSaveExtras, label: intl.formatMessage({ id: 'ListingPage.saveExtras' }), sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Forest }) }))] })))] }));
};
export default BookingExtrasSelection;
