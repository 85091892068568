var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { CampStyle } from '@nomady/shared/types';
import { loadResults, setCampCategory, setFeatures, setPrivate, } from '../../slices/listingSearchSlice';
import { IconVariant } from '../Icon/Icon';
import CampStyleToggle from '../CampStyleToggle/CampStyleToggle';
import LabeledToggle, { LabeledToggleColorVariant, } from '../LabeledToggle/LabeledToggle';
import FireplaceFilterGroup from './FilterGroups/FireplaceFilterGroup';
import ActivitiesFilterGroup from './FilterGroups/ActivitiesFilterGroup';
import PriceFilterGroup from './FilterGroups/PriceFilterGroup';
import FacilitiesFilterGroup from './FilterGroups/FacilitiesFilterGroup';
import AmbianceFilterGroup from './FilterGroups/AmbianceFilterGroup';
import AccessFilterGroup from './FilterGroups/AccessFilterGroup';
import Text, { TextVariant } from '../Text/Text';
import { setPage } from '../../containers/SearchPage/SearchPageSlice';
import { ColorVariant } from '../../theme';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const CampStyleGridWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CampStyleGrid = styled.div `
  display: grid;

  grid-template-columns: 1fr 1fr;

  gap: 8px;
  grid-auto-rows: auto;

  @media ${props => props.theme.device.mobile} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const AdditionalOptionsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SearchBarSheetFeatures = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const intl = useIntl();
    const dispatch = useDispatch();
    const _o = useSelector((state) => state.listingSearch.currentSearchQuery).features, { campStyle } = _o, currentFeaturesSearchQuery = __rest(_o, ["campStyle"]);
    const onSetCampCategory = (newValue) => {
        dispatch(setCampCategory(newValue));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onSetPrivate = (newValue) => {
        dispatch(setPrivate(newValue));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onSetDogsAllowed = (dogsAllowed) => {
        dispatch(setFeatures({ dogsAllowed }));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onSetDogCamp = (dogCamp) => {
        dispatch(setFeatures({ dogCamp }));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onSetFamilyFriendly = (familyFriendly) => {
        dispatch(setFeatures({ familyFriendly }));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const onSetFeatures = (newFeatures) => {
        dispatch(setFeatures(newFeatures));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    return (_jsxs(Wrapper, { children: [_jsxs(CampStyleGridWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({
                            id: 'MarketplaceConfig.camping',
                        }) })), _jsxs(CampStyleGrid, { children: [_jsx(CampStyleToggle, { iconVariant: IconVariant.Tent_NEW, label: intl.formatMessage({ id: 'MarketplaceConfig.tent' }), onChange: () => onSetCampCategory(CampStyle.tent), checked: !!((_a = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _a === void 0 ? void 0 : _a.includes(CampStyle.tent)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.Rooftent, label: intl.formatMessage({
                                    id: 'MarketplaceConfig.carWithRooftopTent',
                                }), onChange: () => onSetCampCategory(CampStyle.carWithRooftopTent), checked: !!((_b = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _b === void 0 ? void 0 : _b.includes(CampStyle.carWithRooftopTent)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.Van_NEW, label: intl.formatMessage({
                                    id: 'MarketplaceConfig.smallVehicle.noLength',
                                }), info: "<6 m", onChange: () => onSetCampCategory(CampStyle.smallVehicle), checked: !!((_c = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _c === void 0 ? void 0 : _c.includes(CampStyle.smallVehicle)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.Motorhome_NEW, label: intl.formatMessage({
                                    id: 'MarketplaceConfig.mediumVehicle.noLength',
                                }), info: "6-8 m", onChange: () => onSetCampCategory(CampStyle.mediumVehicle), checked: !!((_d = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _d === void 0 ? void 0 : _d.includes(CampStyle.mediumVehicle)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.MotorhomePlus_NEW, label: intl.formatMessage({
                                    id: 'MarketplaceConfig.largeVehicle.noLength',
                                }), info: ">8 m", onChange: () => onSetCampCategory(CampStyle.largeVehicle), checked: !!((_e = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _e === void 0 ? void 0 : _e.includes(CampStyle.largeVehicle)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.Caravan_NEW, label: intl.formatMessage({ id: 'MarketplaceConfig.caravan' }), onChange: () => onSetCampCategory(CampStyle.caravan), checked: !!((_f = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _f === void 0 ? void 0 : _f.includes(CampStyle.caravan)) })] })] }), _jsxs(CampStyleGridWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({
                            id: 'MarketplaceConfig.accommodation',
                        }) })), _jsxs(CampStyleGrid, { children: [_jsx(CampStyleToggle, { iconVariant: IconVariant.Cabin, label: intl.formatMessage({ id: 'MarketplaceConfig.hut' }), onChange: () => onSetCampCategory(CampStyle.hut), checked: !!((_g = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _g === void 0 ? void 0 : _g.includes(CampStyle.hut)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.TipiNew, label: intl.formatMessage({ id: 'MarketplaceConfig.glamping' }), onChange: () => onSetCampCategory(CampStyle.glamping), checked: !!((_h = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _h === void 0 ? void 0 : _h.includes(CampStyle.glamping)) }), _jsx(CampStyleToggle, { iconVariant: IconVariant.Roof, label: intl.formatMessage({ id: 'MarketplaceConfig.shelter' }), onChange: () => onSetCampCategory(CampStyle.shelter), checked: !!((_j = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _j === void 0 ? void 0 : _j.includes(CampStyle.shelter)) })] })] }), _jsxs(AdditionalOptionsWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({
                            id: 'SearchBarSheet.Features.mostPopularFilters',
                        }) })), _jsx(LabeledToggle, { iconVariant: IconVariant.Camp_to_yourself, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetPrivate(value), checked: (campStyle === null || campStyle === void 0 ? void 0 : campStyle.private) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.CampStyle.privateCamp',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Directbooking_Filled, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFeatures({
                            bookingAndCancellation: { instantBooking: value || undefined },
                        }), checked: ((_k = currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.bookingAndCancellation) === null || _k === void 0 ? void 0 : _k.instantBooking) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.instantBooking',
                        }), description: intl.formatMessage({
                            id: 'SearchBarSheet.Features.instantBookingDescription',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Verified_filled, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFeatures({
                            bookingAndCancellation: { verifiedHost: value },
                        }), checked: ((_l = currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.bookingAndCancellation) === null || _l === void 0 ? void 0 : _l.verifiedHost) ||
                            false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.verifiedHost',
                        }), description: intl.formatMessage({
                            id: 'SearchBarSheet.Features.verifiedHostDescription',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Dog_NEW, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetDogsAllowed(value), checked: (currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.dogsAllowed) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.dogsAllowed',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Dog_NEW, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetDogCamp(value), checked: (currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.dogCamp) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.dogCamp',
                        }), description: intl.formatMessage({
                            id: 'SearchBarSheet.Features.dogCampDescription',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Child_friendly, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFamilyFriendly(value), checked: (currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.familyFriendly) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.familyFriendly',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Flex, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFeatures({
                            bookingAndCancellation: { flexibleCancellation: value },
                        }), checked: ((_m = currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.bookingAndCancellation) === null || _m === void 0 ? void 0 : _m.flexibleCancellation) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.flexibleCancellation',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Farmshop, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFeatures({ farmShop: value }), checked: (currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.farmShop) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.farmShop',
                        }) }), _jsx(LabeledToggle, { iconVariant: IconVariant.Fireplace, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFeatures({ fireplaceAvailable: value }), checked: (currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.fireplaceAvailable) || false, label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.fireplaceAvailable',
                        }) })] }), _jsxs(AdditionalOptionsWrapper, { children: [_jsx(FacilitiesFilterGroup, { currentFeaturesSearchQuery: currentFeaturesSearchQuery, intl: intl, onSetFeatures: onSetFeatures }), _jsx(PriceFilterGroup, { currentFeaturesSearchQuery: currentFeaturesSearchQuery, intl: intl, onSetFeatures: onSetFeatures }), _jsx(FireplaceFilterGroup, { currentFeaturesSearchQuery: currentFeaturesSearchQuery, intl: intl, onSetFeatures: onSetFeatures }), _jsx(AccessFilterGroup, { currentFeaturesSearchQuery: currentFeaturesSearchQuery, intl: intl, onSetFeatures: onSetFeatures }), _jsx(AmbianceFilterGroup, { currentFeaturesSearchQuery: currentFeaturesSearchQuery, intl: intl, onSetFeatures: onSetFeatures }), _jsx(ActivitiesFilterGroup, { currentFeaturesSearchQuery: currentFeaturesSearchQuery, intl: intl, onSetFeatures: onSetFeatures })] })] }));
};
export default SearchBarSheetFeatures;
