import { MarketplaceCurrency, MarketplaceShippingCountry } from '../config';
export const GIFT_CARD_CODE_LENGTH = 16;
export const GIFT_CARD_CODE_CHARACTER_POOL = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
export const MARKETPLACE_CURRENCY = 'CHF';
export var GIFT_CARD_SALE_TYPE;
(function (GIFT_CARD_SALE_TYPE) {
    GIFT_CARD_SALE_TYPE["DIGITAL_GIFT_CARD"] = "digital-gift-card";
    GIFT_CARD_SALE_TYPE["PHYSICAL_GIFT_CARD"] = "physical-gift-card";
    GIFT_CARD_SALE_TYPE["CUP_ONLY"] = "cup-only";
    GIFT_CARD_SALE_TYPE["SCARF_ONLY"] = "scarf-only";
    GIFT_CARD_SALE_TYPE["GIFT_CARD_BOX"] = "gift-card-box";
    GIFT_CARD_SALE_TYPE["GIFT_CARD_BOX_CAMPER"] = "gift-card-box-camper";
    GIFT_CARD_SALE_TYPE["GIFT_CARD_BOX_ALL_IN_ONE"] = "gift-card-box-all-in-one";
})(GIFT_CARD_SALE_TYPE || (GIFT_CARD_SALE_TYPE = {}));
export const AVAILABLE_GIFT_CARD_VALUES = [
    25, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
    700, 750, 800, 850, 900, 950, 1000,
];
export const SHOP_PRICE_LIST = {
    [GIFT_CARD_SALE_TYPE.CUP_ONLY]: {
        [MarketplaceShippingCountry.DE]: {
            [MarketplaceCurrency.EUR]: 14.95,
        },
    },
    [GIFT_CARD_SALE_TYPE.SCARF_ONLY]: {
        [MarketplaceShippingCountry.DE]: {
            [MarketplaceCurrency.EUR]: 19.95,
        },
        [MarketplaceShippingCountry.CH_EU]: {
            [MarketplaceCurrency.CHF]: 21.95,
            [MarketplaceCurrency.EUR]: 23.4,
        },
    },
    [GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX]: {
        [MarketplaceShippingCountry.DE]: {
            [MarketplaceCurrency.EUR]: 26.95,
        },
        [MarketplaceShippingCountry.CH_EU]: {
            [MarketplaceCurrency.CHF]: 29.95,
            [MarketplaceCurrency.EUR]: 31.9,
        },
    },
    [GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX_CAMPER]: {
        [MarketplaceShippingCountry.DE]: {
            [MarketplaceCurrency.EUR]: 21.95,
        },
    },
    [GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX_ALL_IN_ONE]: {
        [MarketplaceShippingCountry.DE]: {
            [MarketplaceCurrency.EUR]: 37.55,
        },
    },
};
