var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { updatedEntities, denormalisedEntities, } from '@nomady/shared/utils/data';
// ================ Action types ================ //
export const ADD_MARKETPLACE_ENTITIES = 'app/marketplaceData/ADD_MARKETPLACE_ENTITIES';
export const RESET_MARKETPLACE_ENTITIES = 'app/marketplaceData/RESET_MARKETPLACE_ENTITIES';
export const REGISTER_PENDING_REQUEST = 'app/marketplaceData/REGISTER_PENDING_REQUEST';
export const COMPLETE_PENDING_REQUEST = 'app/marketplaceData/COMPLETE_PENDING_REQUEST';
export const UNREGISTER_PENDING_REQUEST = 'app/marketplaceData/UNREGISTER_PENDING_REQUEST';
export const SET_PENDING_REQUEST_DATA = 'app/marketplaceData/SET_PENDING_REQUEST_DATA';
const initialState = {
    // Database of all the fetched entities.
    entities: {},
    pendingRequests: [],
};
const merge = (state, sdkResponse) => {
    const apiResponse = sdkResponse.data;
    return Object.assign(Object.assign({}, state), { entities: updatedEntities(Object.assign({}, state.entities), apiResponse) });
};
export default function marketplaceDataReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_MARKETPLACE_ENTITIES:
            return Object.assign({}, initialState);
        case ADD_MARKETPLACE_ENTITIES:
            return merge(state, action.payload);
        case REGISTER_PENDING_REQUEST:
            return Object.assign(Object.assign({}, state), { pendingRequests: [
                    ...state.pendingRequests,
                    {
                        requestId: action.payload.requestId,
                        timestamp: Date.now(),
                        completed: false,
                    },
                ] });
        case SET_PENDING_REQUEST_DATA:
            return Object.assign(Object.assign({}, state), { pendingRequests: state.pendingRequests.map(req => req.requestId === action.payload.requestId
                    ? Object.assign(Object.assign({}, req), { data: action.payload.data }) : req) });
        case COMPLETE_PENDING_REQUEST:
            return Object.assign(Object.assign({}, state), { pendingRequests: state.pendingRequests.map(req => req.requestId === action.payload.requestId
                    ? Object.assign(Object.assign({}, req), { completed: true }) : req) });
        case UNREGISTER_PENDING_REQUEST:
            return Object.assign(Object.assign({}, state), { pendingRequests: state.pendingRequests.filter(req => req.requestId !== action.payload.requestId) });
        default:
            return state;
    }
}
// ================ Selectors ================ //
export const getListing = (state, id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    const hasListing = listings.length === 1;
    const translationDataPresent = hasListing && listings[0].attributes.publicData.originalLocale;
    return translationDataPresent ? listings[0] : null;
};
export const getPendingRequests = (state) => {
    return state.marketplaceData.pendingRequests;
};
/**
 * Get the denormalised listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
    const { entities } = state.marketplaceData;
    const resources = listingIds.map(id => ({
        id,
        type: 'listing',
    }));
    const throwIfNotFound = false;
    return denormalisedEntities(entities, resources, throwIfNotFound);
};
/**
 * Get the denormalised entities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to entities that
 * we want to query from the data. Currently we expect that all the
 * entities have the same type.
 *
 * @return {Array<Object>} denormalised entities
 */
export const getMarketplaceEntities = (state, entityRefs) => {
    const { entities } = state.marketplaceData;
    const throwIfNotFound = false;
    return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};
// Action creator for adding marketplace entities without request ID
export const addMarketplaceEntities = (sdkResponse) => {
    return {
        type: ADD_MARKETPLACE_ENTITIES,
        payload: sdkResponse,
    };
};
// Thunk action creator for adding marketplace entities with request ID
export const addMarketplaceEntitiesWithSequence = (sdkResponse, requestId) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        const state = getState();
        // Optimistically add the entities
        dispatch({
            type: ADD_MARKETPLACE_ENTITIES,
            payload: sdkResponse,
        });
        const pendingRequests = state.marketplaceData.pendingRequests;
        const currentRequest = pendingRequests.find(r => r.requestId === requestId);
        if (!currentRequest)
            return Promise.resolve();
        // If there are incomplete older requests, wait for them first
        if (pendingRequests.filter(r => r.timestamp < currentRequest.timestamp && !r.completed).length > 0) {
            dispatch({
                type: SET_PENDING_REQUEST_DATA,
                payload: { requestId, data: sdkResponse },
            });
        }
        else {
            dispatch({
                type: COMPLETE_PENDING_REQUEST,
                payload: { requestId },
            });
        }
        // Process any newer pending requests that have data but aren't completed
        pendingRequests
            .filter(req => !req.completed && req.data && req.timestamp > currentRequest.timestamp)
            .forEach(req => {
            dispatch({
                type: ADD_MARKETPLACE_ENTITIES,
                payload: req.data,
            });
            dispatch({
                type: COMPLETE_PENDING_REQUEST,
                payload: { requestId: req.requestId },
            });
        });
    });
};
export const registerPendingRequest = (requestId) => ({
    type: REGISTER_PENDING_REQUEST,
    payload: { requestId },
});
export const unregisterPendingRequest = (requestId) => ({
    type: UNREGISTER_PENDING_REQUEST,
    payload: { requestId },
});
export const resetMarketplaceEntities = () => ({
    type: RESET_MARKETPLACE_ENTITIES,
});
