import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Page from '@nomady/shared/routeConfiguration/Page';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import ConnectedBookingCard from '../../styled-components/BookingCard/ConnectedBookingCard';
import FilterDropdown from '../../styled-components/FilterDropdown/FilterDropdown';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import PaginationControl from '../../styled-components/PaginationControl/PaginationControl';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import { DEFAULT_PAGE_SIZE, loadData, ONLY, requestTodoTransactions, requestTransactions, setOnly, setPage, setTodoPage, showTodoSelector, TODO_PAGE_SIZE, } from './BookingsPageSlice';
import { ColorVariant } from '../../theme';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../../styled-components/SkeletonLoader/SkeletonLoader';
import BookingCardTableHead from '../../styled-components/BookingCard/BookingCardTableHead';
import EmptyCard, { EmptyCardVariant, } from '../../styled-components/EmptyCard/EmptyCard';
import { routeConfiguration } from '../..';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { IconVariant } from '../../styled-components/Icon/Icon';
import PlateShopAdvert from '../../styled-components/PlateShopAdvert/PlateShopAdvert';
const Head = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 18px;
`;
const Section = styled.section `
  margin-bottom: 52px;
`;
const SectionTitle = styled(Text) `
  margin-bottom: 10px;
`;
const Bookings = styled.div `
  display: grid;

  grid-auto-rows: auto;

  row-gap: 10px;

  margin-bottom: 52px;
`;
const Pagination = styled.div `
  width: min-content;
  margin: 0 auto;
`;
const ButtonsGroup = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
const BookingsPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const title = intl.formatMessage({ id: 'BookingsPage.title' });
    const state = useSelector((state) => state);
    const showTodo = useSelector(showTodoSelector);
    const { fetchTransactionsInProgress, fetchTodoTransactionsInProgress, transactionRefs, todoTransactionRefs, pagination, currentPage, currentTodoPage, todoPagination, only, } = useSelector((state) => state.BookingsPage);
    const onChangePage = (page) => {
        dispatch(setPage(page));
        dispatch(requestTransactions());
    };
    const onChangeTodoPage = (page) => {
        dispatch(setTodoPage(page));
        dispatch(requestTodoTransactions());
    };
    const onChangeOrderBy = (only) => {
        dispatch(setOnly(only));
        dispatch(loadData());
    };
    const transactions = getMarketplaceEntities(state, transactionRefs);
    const todoTransactions = getMarketplaceEntities(state, todoTransactionRefs);
    const titleId = {
        [ONLY.FUTURE_TRANSACTIONS]: 'BookingsPage.futureTransactionsTitle',
        [ONLY.PAST_TRANSACTIONS]: 'BookingsPage.pastTransactionsTitle',
        [ONLY.OVERVIEW]: 'BookingsPage.futureTransactionsTitle',
    };
    const showBookingCardTableHead = !!(only === ONLY.OVERVIEW &&
        ((pagination === null || pagination === void 0 ? void 0 : pagination.totalItems) || (todoPagination === null || todoPagination === void 0 ? void 0 : todoPagination.totalItems))) ||
        !!((only === ONLY.FUTURE_TRANSACTIONS || only === ONLY.PAST_TRANSACTIONS) &&
            (pagination === null || pagination === void 0 ? void 0 : pagination.totalItems));
    return (_jsx(SignedInPageContainer, Object.assign({ title: title }, { children: _jsxs(LayoutSideNavigation, { children: [_jsxs(Head, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H20, as: "h1" }, { children: intl.formatMessage({ id: 'BookingsPage.title' }) })), _jsxs(ButtonsGroup, { children: [_jsx(FilterDropdown, { onChange: filter => {
                                        onChangeOrderBy(filter);
                                    }, filters: [
                                        { key: ONLY.OVERVIEW, label: 'BookingsPage.orderByOverview' },
                                        // {
                                        //   key: ONLY.FUTURE_TRANSACTIONS,
                                        //   label: 'BookingsPage.orderByFutureTransactions',
                                        // },
                                        {
                                            key: ONLY.PAST_TRANSACTIONS,
                                            label: 'BookingsPage.orderByPastTransactions',
                                        },
                                    ], filterSelection: only }), _jsx(Button, Object.assign({ sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Forest, icon: IconVariant.Calendar_NEW, targetRoute: Page.InboxCalendar }, { children: intl.formatMessage({ id: 'BookingsPage.switchToCalendar' }) }))] })] }), showBookingCardTableHead && _jsx(BookingCardTableHead, {}), showTodo && (_jsxs(Section, { children: [_jsx(SectionTitle, Object.assign({ as: "h2", variant: TextVariant.H50, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({ id: 'BookingsPage.todoTitle' }) })), _jsxs(Bookings, { children: [!fetchTodoTransactionsInProgress &&
                                    todoTransactions.map(transaction => (_jsx(ConnectedBookingCard, { transaction: transaction, unread: true }, transaction.id.uuid))), fetchTodoTransactionsInProgress && (_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.BookingCards })), !fetchTodoTransactionsInProgress &&
                                    (todoPagination === null || todoPagination === void 0 ? void 0 : todoPagination.totalItems) === 0 && (_jsx(EmptyCard, { description: intl.formatMessage({
                                        id: 'BookingPage.noTodoDescription',
                                    }), buttonLabel: intl.formatMessage({
                                        id: 'BookingPage.noTodoButtonLabel',
                                    }), button: {
                                        onClick: () => {
                                            dispatch(requestTodoTransactions());
                                        },
                                    } }))] }), !fetchTodoTransactionsInProgress &&
                            todoPagination &&
                            TODO_PAGE_SIZE < todoPagination.totalItems && (_jsx(Pagination, { children: _jsx(PaginationControl, { onChangePage: page => onChangeTodoPage(page), activePage: currentTodoPage, totalPages: (todoPagination === null || todoPagination === void 0 ? void 0 : todoPagination.totalPages) || 1 }) }))] })), _jsxs(Section, { children: [_jsx(SectionTitle, Object.assign({ as: "h2", variant: TextVariant.H50, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({ id: titleId[only] }) })), _jsxs(Bookings, { children: [!fetchTransactionsInProgress &&
                                    transactions.map(transaction => (_jsx(ConnectedBookingCard, { transaction: transaction }, transaction.id.uuid))), fetchTransactionsInProgress && (_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.BookingCards })), !fetchTransactionsInProgress && (pagination === null || pagination === void 0 ? void 0 : pagination.totalItems) === 0 && (_jsx(EmptyCard, { variant: EmptyCardVariant.WithIllustration, description: intl.formatMessage({
                                        id: 'BookingPage.noBookingsDescription',
                                    }), buttonLabel: intl.formatMessage({
                                        id: 'BookingPage.noBookingsButtonLabel',
                                    }), button: {
                                        onClick: () => {
                                            const path = pathByRouteName(Page.Host, routeConfiguration);
                                            history.push(path);
                                        },
                                    } }))] }), pagination && DEFAULT_PAGE_SIZE < pagination.totalItems && (_jsx(Pagination, { children: _jsx(PaginationControl, { onChangePage: page => onChangePage(page), activePage: currentPage, totalPages: (pagination === null || pagination === void 0 ? void 0 : pagination.totalPages) || 1 }) }))] }), _jsx(PlateShopAdvert, {})] }) })));
};
export default BookingsPage;
