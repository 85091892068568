import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
const Wrapper = styled.div `
  position: absolute;
  top: 8px;
  margin-left: -16px;

  width: 32px;
  height: 32px;
  padding: 0;
  border: 0;
`;
const Knob = styled.div `
  position: absolute;

  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 1px solid ${props => props.theme.color.indigo};
  border-radius: 50%;
  background-color: ${props => props.theme.color.snow};
  cursor: pointer;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }

  ${props => props.isDragged &&
    css `
      transform: scale(1.1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    `}
`;
const Handle = ({ min, max, positionToValue, onChange, value, valueToPosition, changeActive, onMouseUp, }) => {
    const handleRef = useRef(null);
    const [dragging, setDragging] = useState(false);
    const [relativePosition, setRelativePosition] = useState(null);
    const onMouseMove = (event) => {
        if (!dragging)
            return;
        const pageX = 'touches' in event ? event.touches[0].pageX : event.pageX;
        onMove(pageX);
    };
    const onMouseUpWrapper = (event) => {
        if (!('touches' in event)) {
            event.preventDefault();
        }
        event.stopPropagation();
        onEnd();
    };
    const onStart = (pagePosition) => {
        if (!handleRef.current) {
            return;
        }
        const { offsetLeft, offsetWidth } = handleRef.current;
        setDragging(true);
        setRelativePosition(pagePosition - offsetLeft - offsetWidth / 2);
        changeActive();
    };
    const onMove = (pagePosition) => {
        const position = pagePosition - (relativePosition || 0);
        const currentValue = positionToValue(position);
        const valueBetweenMinMax = Math.min(Math.max(currentValue, min), max);
        onChange(valueBetweenMinMax);
    };
    const onEnd = () => {
        setDragging(false);
        onMouseUp();
    };
    useEffect(() => {
        const onMouseMoveListener = onMouseMove;
        const onMouseUpListener = onMouseUpWrapper;
        window.addEventListener('mousemove', onMouseMoveListener, false);
        window.addEventListener('mouseup', onMouseUpListener, false);
        window.addEventListener('touchmove', onMouseMoveListener);
        window.addEventListener('touchend', onMouseUpListener);
        return () => {
            window.removeEventListener('mousemove', onMouseMoveListener);
            window.removeEventListener('mouseup', onMouseUpListener);
            window.removeEventListener('touchmove', onMouseMoveListener);
            window.removeEventListener('touchend', onMouseUpListener);
        };
    }, [dragging, relativePosition]);
    const position = valueToPosition(value);
    return (_jsx(Wrapper, Object.assign({ ref: handleRef, style: { left: `${position}px` }, onMouseDown: (event) => {
            event.stopPropagation();
            event.preventDefault();
            onStart(event.pageX);
        }, onTouchStart: (event) => {
            event.stopPropagation();
            event.preventDefault();
            onStart(event.touches[0].pageX);
        }, role: "button" }, { children: _jsx(Knob, { isDragged: dragging }) })));
};
export default Handle;
