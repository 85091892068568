import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { GIFT_CARD_SALE_TYPE } from '@nomady/shared/gift-card/config';
import Text, { TextVariant } from '../../Text/Text';
import PhysicalGiftCardPreview from '../../../assets/241104_Shop_Bilder_Lay2.png';
import DigitalGiftCardPreview from '../../../assets/241104_Shop_Bilder_Lay3.png';
import TasseMockUp from '../../../assets/241104_Shop_Bilder_7.png';
import GiftCardShopItemForm from './GiftCardShopItemForm';
import BuffMockUp from '../../../assets/241104_Shop_Bilder_6.png';
import GutscheinboxMockUp from '../../../assets/241104_Shop_Bilder_3.png';
import GutscheinboxCamperMockUp from '../../../assets/241104_Shop_Bilder_2.png';
import GutscheinboxAllInOneMockUp from '../../../assets/241104_Shop_Bilder_.png';
import { ColorVariant } from '../../../theme';
const Item = styled.div `
  padding-bottom: 44px;
  border-bottom: 1px solid ${props => props.theme.color.fog};
  margin-bottom: 46px;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tabletL} {
    align-items: flex-start;
    flex-direction: row;
  }
`;
const Left = styled.div `
  margin-right: 40px;
  flex-shrink: 0;

  @media ${props => props.theme.device.tabletL} {
    margin-right: 80px;
    ${props => props.theme.layout.col6}
  }
`;
const Right = styled.div `
  flex-grow: 1;
`;
const imageSrc = {
    [GIFT_CARD_SALE_TYPE.DIGITAL_GIFT_CARD]: DigitalGiftCardPreview,
    [GIFT_CARD_SALE_TYPE.PHYSICAL_GIFT_CARD]: PhysicalGiftCardPreview,
    [GIFT_CARD_SALE_TYPE.CUP_ONLY]: TasseMockUp,
    [GIFT_CARD_SALE_TYPE.SCARF_ONLY]: BuffMockUp,
    [GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX]: GutscheinboxMockUp,
    [GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX_CAMPER]: GutscheinboxCamperMockUp,
    [GIFT_CARD_SALE_TYPE.GIFT_CARD_BOX_ALL_IN_ONE]: GutscheinboxAllInOneMockUp,
};
const Preview = styled.img `
  height: auto;
  width: 100%;

  margin-bottom: 20px;
`;
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const BoldText = styled(Text) `
  font-weight: 700;
`;
const GiftCardShopItem = ({ type }) => {
    const intl = useIntl();
    return (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({ id: `GiftCardShopItemForm.title.${type}` }) })), _jsxs(Item, { children: [_jsxs(Left, { children: [_jsx(Preview, { src: imageSrc[type] }), _jsx(Text, Object.assign({ variant: TextVariant.B50, as: "p", colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                    id: `GiftCardShopItemForm.description.${type}`,
                                }) })), _jsx(BoldText, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: [
                                    GIFT_CARD_SALE_TYPE.DIGITAL_GIFT_CARD,
                                    GIFT_CARD_SALE_TYPE.PHYSICAL_GIFT_CARD,
                                ].includes(type)
                                    ? intl.formatMessage({
                                        id: `GiftCardShopItemForm.description.currencyInfo`,
                                    })
                                    : intl.formatMessage({
                                        id: `GiftCardShopItemForm.description.shippingCountryInfo.${type}`,
                                    }) }))] }), _jsx(Right, { children: _jsx(GiftCardShopItemForm, { type: type }) })] })] }));
};
export default GiftCardShopItem;
