import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IconVariant } from '../Icon/Icon';
import ListingModal from '../../containers/ListingPage/Sections/ListingModal';
import BookingDatesSelection from '../../containers/ListingPage/ModalContents/BookingDatesSelection';
import BookingGuestsSelection from '../../containers/ListingPage/ModalContents/BookingGuestsSelection';
import BookingExtrasSelection from '../../containers/ListingPage/ModalContents/BookingExtrasSelection';
import { ListingContentModalVariant, setBookingData, setBookingEnd, setBookingStart, setGuestsChecked, } from '../../containers/ListingPage/ListingPageSlice';
import { ContentVariant } from '../InfoLabel';
import SeatsAvailabilityIndicator from '../SeatsAvailabilityIndicator/SeatsAvailabilityIndicator';
import { DEFAULT_ADULT_QUANTITY, DEFAULT_SEAT_QUANTITY, } from '@nomady/shared/config';
import { localeSelector } from '../../slices/UISlice';
import { defaultBookingData } from '../../containers/CheckoutPage/utils/BookingData';
export const getBookingCalendarTitle = (intl, locale, bookingStart, bookingEnd) => {
    return bookingStart && bookingEnd
        ? `${moment(bookingStart).locale(locale).format('dd. D.')} - ${moment(bookingEnd)
            .locale(locale)
            .format('dd. D. MMM. YYYY')}`
        : intl.formatMessage({ id: 'CheckoutPanels.arrivalAndDeparture' });
};
export const getGuestsTitle = (intl, guestsChanged, totalGuests) => {
    return `${guestsChanged ? `${totalGuests} ` : ''}${intl.formatMessage({
        id: 'CheckoutPanels.guestsQuantity',
    }, { quantity: totalGuests })}`;
};
export const getExtrasTitle = (intl) => intl.formatMessage({
    id: 'CheckoutPanels.extras',
});
export const getExtrasSubtitle = (intl, powerSupply, firewood) => {
    const parts = [];
    if (powerSupply) {
        parts.push(intl.formatMessage({ id: 'CheckoutPanels.powerSupply' }));
    }
    if (firewood) {
        parts.push(intl.formatMessage({ id: 'CheckoutPanels.firewood' }));
    }
    return parts.join(' | ');
};
export const getGuestsSubtitle = (intl, adultQuantity, teenQuantity, childQuantity, infantQuantity, dogQuantity) => {
    const youthSubtitleAddition = teenQuantity
        ? ` | ${intl.formatMessage({
            id: 'CheckoutPanels.shortYouths',
        }, { quantity: teenQuantity })}`
        : '';
    const childrenSubtitleAddition = childQuantity
        ? ` | ${intl.formatMessage({
            id: 'CheckoutPanels.shortChildren',
        }, { quantity: childQuantity })}`
        : '';
    const infantSubtitleAddition = infantQuantity
        ? ` | ${intl.formatMessage({
            id: 'CheckoutPanels.shortInfants',
        }, { quantity: infantQuantity })}`
        : '';
    const dogsSubtitleAddition = dogQuantity
        ? ` | ${intl.formatMessage({
            id: 'CheckoutPanels.shortDogs',
        }, { quantity: dogQuantity })}`
        : '';
    const guestsButtonSubtitle = `${intl.formatMessage({
        id: 'CheckoutPanels.shortAdults',
    }, { quantity: adultQuantity })}${youthSubtitleAddition}${childrenSubtitleAddition}${infantSubtitleAddition}${dogsSubtitleAddition}`;
    return guestsButtonSubtitle;
};
const CheckoutPanels = ({ seatQuantity, adultQuantity, teenQuantity = 0, childQuantity = 0, infantQuantity = 0, dogQuantity = 0, bookingStart, bookingEnd, totalSeats, availableSeats, showSeatSelection, showExtras = false, extras, }) => {
    const intl = useIntl();
    const locale = useSelector(localeSelector);
    const dispatch = useDispatch();
    const guestsChanged = seatQuantity !== DEFAULT_SEAT_QUANTITY ||
        adultQuantity !== DEFAULT_ADULT_QUANTITY ||
        !!teenQuantity ||
        !!childQuantity ||
        !!infantQuantity;
    const totalGuests = adultQuantity + teenQuantity + childQuantity + infantQuantity + dogQuantity;
    const guestsButtonSubtitle = getGuestsSubtitle(intl, adultQuantity, teenQuantity, childQuantity, infantQuantity, dogQuantity);
    const extrasButtonSubtitle = getExtrasSubtitle(intl, extras === null || extras === void 0 ? void 0 : extras.powerSupply, extras === null || extras === void 0 ? void 0 : extras.firewood);
    const showIndicator = bookingStart &&
        bookingEnd &&
        totalSeats &&
        totalSeats > 1 &&
        availableSeats;
    const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
    const [isInvalidDatesRange, setIsInvalidDatesRange] = useState(false);
    const [isGuestsModalOpen, setIsGuestsModalOpen] = useState(false);
    const [isExtrasModalOpen, setIsExtrasModalOpen] = useState(false);
    const showDatesResetButton = !!bookingStart;
    const onClickResetDates = () => {
        dispatch(setBookingStart(undefined));
        dispatch(setBookingEnd(undefined));
        setIsInvalidDatesRange(false);
    };
    const showGuestsResetButton = seatQuantity !== DEFAULT_SEAT_QUANTITY ||
        adultQuantity !== DEFAULT_ADULT_QUANTITY ||
        !!teenQuantity ||
        !!childQuantity ||
        !!infantQuantity ||
        !!dogQuantity;
    const onClickResetGuests = () => {
        dispatch(setBookingData(defaultBookingData));
        dispatch(setGuestsChecked(false));
    };
    const onClickSaveGuests = () => {
        dispatch(setGuestsChecked(true));
        setIsGuestsModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ListingModal, Object.assign({ isOpen: isDatesModalOpen, setIsOpen: setIsDatesModalOpen, dataTestId: "CheckoutPanels_BookingDatesPanel", title: getBookingCalendarTitle(intl, locale, bookingStart, bookingEnd), iconVariant: IconVariant.Calendar, id: ListingContentModalVariant.BOOKING_DATES, rightButton: {
                    label: intl.formatMessage({ id: 'ListingPage.saveDates' }),
                    onClick: () => setIsDatesModalOpen(false),
                    isPrimary: true,
                }, leftButton: showDatesResetButton
                    ? {
                        label: intl.formatMessage({ id: 'ListingPage.reset' }),
                        onClick: onClickResetDates,
                    }
                    : undefined }, { children: _jsx(BookingDatesSelection, { onClose: () => setIsDatesModalOpen(false), isInvalidDatesRange: isInvalidDatesRange, setIsInvalidDatesRange: setIsInvalidDatesRange }) })), _jsx(ListingModal, Object.assign({ isOpen: isGuestsModalOpen, setIsOpen: setIsGuestsModalOpen, dataTestId: "CheckoutPanels_BookingGuestsPanel", title: getGuestsTitle(intl, guestsChanged, totalGuests), iconVariant: IconVariant.User, id: ListingContentModalVariant.BOOKING_GUESTS, subtitle: guestsButtonSubtitle, toggleModalVariant: guestsChanged ? ContentVariant.Block : ContentVariant.Inline, leftButton: showGuestsResetButton
                    ? {
                        label: intl.formatMessage({ id: 'ListingPage.reset' }),
                        onClick: onClickResetGuests,
                    }
                    : undefined, rightButton: {
                    label: intl.formatMessage({ id: 'ListingPage.saveGuests' }),
                    onClick: onClickSaveGuests,
                    isPrimary: true,
                } }, { children: _jsx(BookingGuestsSelection, { showSeatSelection: showSeatSelection, availableSeats: availableSeats }) })), showExtras && (_jsx(ListingModal, Object.assign({ isOpen: isExtrasModalOpen, setIsOpen: setIsExtrasModalOpen, dataTestId: "CheckoutPanels_BookingExtrasPanel", title: getExtrasTitle(intl), iconVariant: IconVariant.Extras, id: ListingContentModalVariant.BOOKING_EXTRAS, subtitle: extrasButtonSubtitle, toggleModalVariant: guestsChanged ? ContentVariant.Block : ContentVariant.Inline }, { children: _jsx(BookingExtrasSelection, { hideContinueButton: true }) }))), !!showIndicator && (_jsx(SeatsAvailabilityIndicator, { totalSeats: totalSeats, availableSeats: availableSeats }))] }));
};
export default CheckoutPanels;
